<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr>
    <div>
      <b-card>
        <b-row>
          <b-col cols="4" class="d-flex align-items-center">
            <h4 class="mr-2">
              {{ this.$store.getters.getKurumName }} Vardiya Özeti
              <small>{{
                  vardiyaID == 0 ? currentDate : propDate(this.$store.getters.getVardiya.baslangic) + ' / ' + propDate(this.$store.getters.getVardiya.bitis)
                }}</small>
            </h4>
          </b-col>
          <b-col cols="8" class="justify-content-end text-right">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                class="mr-1 myButton"
                :to="'/giderKayitlari/' + vardiyaID"
            >
              <feather-icon icon="CreditCardIcon" class="mr-50"/>
              <span class="align-middle">Gider Kayıtları</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                class="mr-1 myButton"
                :to="'/konaklamaKayitlari/' + vardiyaID"
            >
              <feather-icon icon="UsersIcon" class="mr-50"/>
              <span class="align-middle">Konaklama Kayıtları</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                class="mr-1 myButton"
                :to="'/gelirKayitlari/' + vardiyaID"
            >
              <feather-icon icon="ListIcon" class="mr-50"/>
              <span class="align-middle">Detaylı Gelir Listesi</span>
            </b-button>
            <!--            <b-button-->
            <!--                v-show="this.$route.params.id != 0"-->
            <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
            <!--                variant="info"-->
            <!--                class="mr-1 myButton"-->
            <!--                to="/vardiyaListesi"-->
            <!--            >-->
            <!--              <feather-icon icon="ChevronLeftIcon" class="mr-50"/>-->
            <!--              <span class="align-middle">Vardiya Kayıtları</span>-->
            <!--            </b-button>-->
          </b-col>
        </b-row>
        <hr/>
        <b-row class="mt-2">
          <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="TrendingUpIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ toplamCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Toplam Ciro</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="DollarSignIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ nakitCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Nakit Gelir</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="CreditCardIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ kKartiCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Kredi Kartı</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="MonitorIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ havaleCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Havale</p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-1">
          <b-col md="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="UserIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ krediCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Cari Kredi</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="UserCheckIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ krediTahReturn }} TL
                </h4>
                <p class="font-small-3 mb-0">Kredi Tahsilatları</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="AlertOctagonIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ vardiyaGideri }} TL
                </h4>
                <p class="font-small-3 mb-0">Vardiya Giderleri</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="ShoppingBagIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ netKasa }} TL
                </h4>
                <p class="font-small-3 mb-0">Net Kasa</p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info" class="pull-up">
                  <feather-icon size="24" icon="ShoppingCartIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ onlineCiro }} TL
                </h4>
                <p class="font-small-3 mb-0">Online Gelir</p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>
      <hr/>
      <b-card class="mt-2" header-bg-variant="cardTitle" header-tag="header">
        <template #header>
          <h4>Detaylı Liste</h4>
        </template>
        <b-tabs class="mt-1">
          <b-tab active>
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Gelir kayıtları</span>
            </template>
            <table class="table-sm table-striped table-hover table-responsive">
              <thead class="text-center">
              <tr>
                <th width="1%" scope="col">REZ NO</th>
                <th scope="col">FİŞ NO</th>
                <th scope="col">DEPARTMAN</th>
                <th scope="col">İŞLEM SAHİBİ</th>
                <th scope="col">KAYIT TÜRÜ</th>
                <th scope="col">MÜŞTERİ ADI</th>
                <th scope="col">TUTAR</th>
                <th scope="col" width="40%">AÇIKLAMA</th>
                <th scope="col">TARİH</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  class="text-center"
                  v-for="(gelir, index) in gelirKayitlari"
                  :key="index"
              >
                <td>{{ gelir.rezervasyonNo }}</td>
                <td>{{ gelir.fisNo }}</td>
                <td>
                  <a
                      class="mr-1"
                      :style="{
                              color:
                                otelNameControl(gelir.departmanAdi) == true
                                  ? ''
                                  : '#6e6b7b!important',
                              cursor:
                                otelNameControl(gelir.departmanAdi) == true
                                  ? ''
                                  : 'default!important',
                            }"
                  >
                    {{ gelir.departmanAdi }}
                  </a>
                </td>
                <td style="white-space: nowrap">{{ gelir.userName }}</td>
                <td>
                  {{
                    gelir.odemeTuru == 'nakit'
                        ? 'Nakit Satış'
                        : gelir.odemeTuru == 'kredikarti'
                            ? 'Kredi Kartı'
                            : gelir.odemeTuru == 'havale'
                                ? 'Havale'
                                : gelir.odemeTuru == 'kredi'
                                    ? 'Kredili Satış'
                                    : ''
                  }}
                </td>
                <td>{{ gelir.adi }} {{ gelir.soyAdi }}</td>
                <td>{{ formatPrice(gelir.gelir) }} TL</td>
                <td width="40%" class="aciklamaTd">{{ gelir.aciklama }}</td>
                <td>{{ gelir.kayitTarih }}</td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Gider kayıtları</span>
            </template>
            <table class="table table-striped table-hover table-responsive">
              <thead class="text-center">
              <tr>
                <th width="20%" scope="col"><p class="text-white">TUTAR</p></th>
                <th width="60%" scope="col"><p class="text-white">AÇIKLAMASI</p></th>
                <th width="15%" scope="col"><p class="text-white">TARİH</p></th>
                <th width="5%" scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  class="text-center"
                  v-for="(gider, index) in giderKayitlari"
                  :key="index"
              >
                <td>{{ formatPrice(gider.tutar) }} TL</td>
                <td>{{ gider.aciklama }}</td>
                <td>{{ gider.kayitTarih }}</td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Tahsilat kayıtları</span>
            </template>
            <table class="table table-striped table-hover table-responsive">
              <thead class="text-center">
              <tr>
                <th scope="col"><p class="text-white">ÜNVAN</p></th>
                <th scope="col"><p class="text-white">İSİM</p></th>
                <th scope="col"><p class="text-white">ÖDEME TÜRÜ</p></th>
                <th scope="col"><p class="text-white">KULLANICI</p></th>
                <th scope="col"><p class="text-white">TUTAR</p></th>
                <th scope="col"><p class="text-white">AÇIKLAMA</p></th>
                <th scope="col"><p class="text-white">TARİH</p></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  class="text-center"
                  v-for="(tah, index) in tahsilatKayitlari"
                  :key="index"
              >
                <td>{{ tah.unvan }}</td>
                <td>{{ tah.isim }}</td>
                <td>{{ tah.tahtur }}</td>
                <td>{{ tah.userName }}</td>
                <td>{{ formatPrice(tah.tutar) }} TL</td>
                <td>{{ tah.aciklama }}</td>
                <td>{{ tah.kayitTarih }}</td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Konaklama kayıtları</span>
            </template>
            <table class="table table-striped table-hover table-responsive">
              <thead class="text-center">
              <tr>
                <th scope="col"><p class="text-white">ODA</p></th>
                <th scope="col"><p class="text-white">REZERVASYON NO</p></th>
                <th scope="col"><p class="text-white">KONUK İSMİ</p></th>
                <th scope="col"><p class="text-white">GİRİŞ TARİHİ</p></th>
                <th scope="col"><p class="text-white">ÇIKIŞ TARİHİ</p></th>
                <th scope="col"><p class="text-white">DURUM</p></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  class="text-center"
                  v-for="(guest, index) in konaklamaListesi"
                  :key="index"
              >
                <td>{{ guest.odaKodu }}</td>
                <td>{{ guest.rezervasyonNo }}</td>
                <td>{{ guest.adi }} {{ guest.soyAdi }}</td>
                <td>{{ guest.girisTarih }}</td>
                <td>{{ guest.cikisTarih }}</td>
                <td>
                  {{
                    guest.odemeDurum == 1 ? 'Tahsilat Tam. ' : 'Tahsilat Bek.'
                  }}
                  /
                  {{ guest.checkOut == 1 ? 'Çıkış Yapıldı. ' : 'Aktif Kon.' }}
                </td>
              </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BCardTitle,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCardTitle,
    vSelect,
    BFormTextarea,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTabs,
    BTab,
  },
  data() {
    return {
      spinner: true,
      newTutar: '',
      choose: '',
      vardiyaID: this.$route.params.id,
      odeme: [
        {
          title: 'Nakit',
          id: 'nakit',
        },
        {
          title: 'Kredi Kartı',
          id: 'kredikarti',
        },
        {
          title: 'Havale',
          id: 'havale',
        },
      ],
      vardiyaSelected: false,
    }
  },
  methods: {
    otelNameControl(name) {
      const finished = name.toLowerCase()
          .split(' ')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < finished.length; i++) {
        return finished[i] === 'otel'
      }
    },
    propDate(data) {
      if (data) {
        return data.slice(0, 16)
      }
      return ''
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2)
          .replace('.', ',')
      return val.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') !== 'NaN'
          ? val.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          : value
    },
  },
  watch: {
    '$route.params.id': function (newVal) {
      if (newVal !== 0) {
        this.$store.dispatch('vardiyaOzeti', {
          vardiyaID: newVal,
        })
      } else {
        this.$store.dispatch('vardiyaOzeti', {
          vardiyaID: 0,
        })
      }
    },
  },
  computed: {
    toplamCiro() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.toplamCiro)
      }
    },
    nakitCiro() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.nakitCiro)
      }
    },
    kKartiCiro() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.kkartiCiro)
      }
    },
    havaleCiro() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.havaleCiro)
      }
    },
    krediCiro() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.krediCiro)
      }
    },
    krediTahReturn() {
      if (this.$store.getters.getVardiya.tahsilatKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.tahsilatKayitlari.tahsilatlar)
      }
    },
    vardiyaGideri() {
      if (this.$store.getters.getVardiya.giderKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.giderKayitlari.giderler)
      }
    },
    netKasa() {
      if (this.$store.getters.getVardiya.gelirKayitlari) {
        return this.formatPrice(this.$store.getters.getVardiya.gelirKayitlari.nakitCiro - this.$store.getters.getVardiya.giderKayitlari.giderler)
      }
    },
    onlineCiro() {
      if (this.$store.getters.getVardiya) {
        return this.formatPrice(this.$store.getters.getVardiya.onlineCiro)
      }
    },
    currentDate() {
      const today = new Date()
      const dd = String(today.getDate())
          .padStart(2, '0')
      const mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      const yyyy = today.getFullYear()
      return yyyy + '/' + mm + '/' + dd
    },
    gelirKayitlari() {
      if (store.getters.getVardiya.gelirKayitlari) {
        return _.orderBy(
            store.getters.getVardiya.gelirKayitlari.gelir,
            'departman',
            'desc',
        )
      }
    },
    giderKayitlari() {
      if (store.getters.getVardiya.giderKayitlari) {
        return _.orderBy(
            store.getters.getVardiya.giderKayitlari.gider,
            'departman',
            'desc',
        )
      }
    },
    tahsilatKayitlari() {
      if (store.getters.getVardiya.tahsilatKayitlari) {
        return _.orderBy(
            store.getters.getVardiya.tahsilatKayitlari.tahsilatList,
            'unvan',
            'desc',
        )
      }
    },
    konaklamaListesi() {
      if (store.getters.getVardiya.konaklamaKayitlari) {
        return _.orderBy(
            store.getters.getVardiya.konaklamaKayitlari,
            'adi',
            'desc',
        )
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('vardiyaAction', 'remove')
  },
  created() {
    store
        .dispatch('vardiyaOzeti', this.vardiyaID)
        .then((res, position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
  },
  directives: {
    Ripple,
  },
}
</script>
<style scoped>
.pull-up {
  transition: 200ms;
}

small {
  font-size: 0.957rem !important;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white !important;
}

.table th,
.table td {
  white-space: nowrap;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 10px !important;
}

.table th,
.table td {
  padding: 0 !important;
  width: 800px !important;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

.table th,
.table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

thead tr {
  white-space: nowrap !important;
  text-align: center !important;
  background-color: #0ea9d7 !important;
  color: white !important;
}

td {
  font-weight: 500;
}

.aciklama td {
  white-space: pre-wrap !important;
}

.card-header {
  display: none !important;
}

.col-8 {
  padding-right: 0 !important;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: transparent !important;
}

.table:not(.table-dark):not(.table-light)
thead:not(.thead-dark)
th[data-v-501c2df2],
.table:not(.table-dark):not(.table-light)
tfoot:not(.thead-dark)
th[data-v-501c2df2] {
  padding: 0 !important;
}

.table-sm th,
.table-sm td {
  padding: 0.1rem !important;
  font-weight: 500 !important;
}
</style>
<style>
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
